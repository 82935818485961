<template>
  <div class="Nav-head">
    <div class="Nav-item" :class="navIndex==item.key?'tab-active':''" v-for="(item,index) in tagLists" :key="index" @click="optionTab(item)">
      <span>{{item.name}}</span>
      <div :class="navIndex===item.key?'tag-line':''" class=""></div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, watch, onMounted } from 'vue'
// import {tagData} from './tagData'
import { showSonTag } from "./tagMethods";
import { props } from "./tagProps";
import { getCodes } from "../TradeDealCard/dealMethods";
export default defineComponent({
  name: "index",
  props: props,
  setup(props, ctx) {
    let data = reactive({ navIndex: 1, tagLists: [] })
    onMounted(() => {
      data.tagLists = props.tagList
      // data.navIndex = data.tagLists[0].key
      // console.log('data.navIndex',data.navIndex,data.tagLists);
    })
    watch(() => props.tagList, (val, prevVal) => {
      data.tagLists = val
      data.navIndex = data.tagLists[0].key
    }
    )
    // let data = reactive(tagData);
    let optionTab = (item) => { showSonTag(data, item, ctx) }
    return {
      ...toRefs(data),
      optionTab
    }
  }
})
</script>

<style scoped lang="less">
.Nav-head {
  cursor: pointer;
  margin-bottom: 20px;
  .Nav-item {
    display: inline-block;
    padding: 0;
    padding-right: 20px;
    height: 25px;
    line-height: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #303133;
    position: relative;
    &:hover {
      color: #3B6EFB ;
    }
    .tag-line {
      position: absolute;
      left: 23px;
      top: 35px;
      width: 30%;
      // border-bottom: 3px solid #3B6EFB ;
      border-radius: 15px;
    }
  }

  .tab-active {
    color: #3B6EFB ;
    font-weight: 700;
  }
}
</style>