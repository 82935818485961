/**
 * @author wuDada
 * @class
 * @name tag标签
 * @description 功能类 组件（prop）参数
 * @param {Number} tableHeight  显示高度，默认为300
 * @param {String} HqType  默认查询股票类型（stock）
 */
export const props = {
    tagList: {
        type: Array,
        default: []
    }
}