<template>
    <div class="echartsContainer">
      <div :ref="myCharts" class="charts" :style="{'height': heightCharts +'px', 'width': '100%'}"></div>
    </div>
</template>

<script>
import {reactive, toRefs, onMounted, onBeforeUnmount, watch} from "vue";
import * as echarts from "echarts";
import {props} from "./optionProps";

let myChart;
export default {
  name: "echarts",
  props: props,
  setup(props,ctx) {
    let refs = '';
    const myCharts = el => {
      refs = el;
    }
    const options = props.option;

    const echartsresize= () => {
      myChart.resize();
    }

    watch(() => props.option,(newVal) => {
      myChart = echarts.init(refs);
      myChart.setOption(newVal);
    })

    onMounted(() => {
      setChart()
      window.addEventListener("resize",echartsresize);
    });

    let setChart = () =>{
      myChart = echarts.init(refs);
      myChart.setOption(options);
    }
    onBeforeUnmount(() => {
      window.removeEventListener('resize',echartsresize)
    })

    return {
      options,
      myCharts
    };
  },
};
</script>

<style lang="less" scoped>
.echartsContainer {
  width: 100%;
  height: 100%;
  .charts {
    width: 100%;
  }
}
</style>
