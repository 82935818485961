// 获取 table 数据
import { getDrafrLists } from "../upreport-manage/Upreport-Draft/drafrMethods";
import { editFiles, getStockListPlans } from "../stock-manage/stockMethods";
import { findTargetamountByAcid } from "../stock-manage/stockApi";
import { findForeignAmountByAcid } from "./controlApi";
import { findFileByFileId } from "../upreport-manage/UprePort-Edit/editApi";
import { findForeignFileByFindId } from "@/views/course-manage/upreport-manage/Upreport-State/startApi.js";
const spreadTableData = {
  data: {},
  parame: {},
};
export const getControlLists = (data) => {
  let classId = sessionStorage.getItem("classId");
  getDrafrLists(data, classId);
};
// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getControlLists(data);
};
// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getControlLists(data);
};
// 获取 table 数据
export const getControlListOfs = (data) => {};
// 切换条数
export const sizeChangeOfs = (val, data) => {
  data.pageSize = val;
  getControlListOfs(data);
};
// 切换页数
export const currentChangeOfs = (val, data) => {
  data.currentPageOf = val;
  let parame = {
    acid: JSON.parse(sessionStorage.getItem("acid")),
    pageSize: data.pageSizeOf,
  };
  spreadTableData.data.currentPageOf = val;
  getSpreadTableOfList(spreadTableData.parame, spreadTableData.data);
  getForeignSpreadTableOfList(parame, data);
};

export const repHtml = (data, id) => {
  if (data.financeRadio == "外汇") {
    findForeignFileByFindId({ fileId: id }).then((res) => {
      if (res.data.code == "200") {
        data.valueFile = res.data.data.files;
      }
    });
  } else {
    findFileByFileId({ fieldIds: [id] }).then((res) => {
      if (res.data.code === "200") {
        let resNew = res.data;
        data.valueFile = resNew.data.tfundFundfiles[0].files;
      }
    });
  }
};

// 点击 操作报告
export const clickButtons = (val, router, data) => {
  console.log("val",val);
  //
  if (val.type === "查看") {
    data.diaStart = true;
    repHtml(data, val.item.fileId);
    data.fileName = val.item.name;
  }
  if (val.type === "通过" || val.type === "否决") {
    let num = val.type === "通过" ? 1 : -2;
    val.item.num = num;

    
    router.push({
      path: "/course/report/edit",
      query: { pageType: 5, financeRadio:data.financeRadio, value: JSON.stringify(val.item) },
    });
  }
  if (val.type === "对应目标") {
    data.loadingOf = true;
    let start =
      val.item.fileState === 0 ? 0 : val.item.fileState === -2 ? -2 : 0;
    let parame = {
      // fmid:1145,
      acid: JSON.parse(sessionStorage.getItem("acid")),
      // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
      lastState: start,
      fileID: val.item.fileId,
      pageSize: data.pageSizeOf,
    };
    spreadTableData.parame = parame;
    spreadTableData.data = data;
    if (data.financeRadio == "外汇") {
      getForeignSpreadTableOfList(parame, data);
    } else {
      getSpreadTableOfList(parame, data);
    }
    
  }
};
// 查询对应调整股票目标表格数据
function getSpreadTableOfList(parame, data) {
  parame.page = data.currentPageOf;
  findTargetamountByAcid(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.spreadTableOf = newRes.tfundTargetamountPageInfo.list;
      data.totalOf = newRes.tfundTargetamountPageInfo.total;
      setTimeout(() => {
        data.loadingOf = false;
      }, 300);
    }
  });
}

// 查询对应币种目标表格数据
function getForeignSpreadTableOfList(parame, data) {
  parame.page = data.currentPageOf;
  findForeignAmountByAcid(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.spreadTableOf = getMoneyName(newRes.currencyTargetAmountPageInfos.list);
      data.totalOf = newRes.currencyTargetAmountPageInfos.total;
      setTimeout(() => {
        data.loadingOf = false;
      }, 300);
    }
  });
}

function getMoneyName (datas) { 
  let currencyOptions = JSON.parse(localStorage.getItem("CurrencyOptions"));
  datas.forEach((item) => {
    for (let i = 0; i < currencyOptions.length; i++) {
      if (item.currencyId == currencyOptions[i].value) {
        item.name = currencyOptions[i].label;
        item.abbr = currencyOptions[i].abbr;
      }
    }
  });

  return datas;
}
