/**
 * @author wuDada
 * @class
 * @name eCharts图表
 * @description 功能类 组件（prop）参数
 * @param {Number} heightCharts  图表高度，默认为400
 * @param {Object} option  图标配置 {}
 */
export const props = {
    heightCharts:{
        type:Number,
        default: 400
    },
    option:{
        type:Object,
        default: {}
    }
}